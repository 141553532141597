<script setup lang="ts">
import { getServerState } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import { defineAsyncComponent, computed, type ComputedRef } from 'vue'
import type { FooterLink, FormattedFooterContent } from '@/format-content'

// Components
const ZgSection = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then((c) => c.ZgSection))
const content: FormattedFooterContent = getServerState('footer-content')!

const links: ComputedRef<FooterLink[] | undefined> = computed(() => content?.links)
const emit = defineEmits<(event: 'track-navigation-click', payload: string) => void>()

const onClick = (item: FooterLink, index?: number) => {
  if (typeof index === 'number' && item.url) {
    emit('track-navigation-click', `toggle-expanded-${item?.label}`)
    window.location.href = item.url
  }
}
</script>

<template>
  <zg-section background="brand-100" no-y-padding>
    <ul class="footer-links">
      <li v-for="(item, index) in (links ?? [])" :key="index">
        <a
          v-if="item"
          :href="item.url"
          :rel="item.nofollow ? 'nofollow' : ''"
          :data-tracking-text="item.label"
          @click.stop="onClick(item)"
        >
          {{ item.label }}
        </a>
      </li>
    </ul>
  </zg-section>
</template>

<style lang="scss" scoped>
  ul {
    display: none;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    gap: rem(32px);
    padding: rem(16px) 0 0;
    margin: 0;
    list-style: none;

    @include mq(medium) {
      display: flex;
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    a {
      @include type-body-xs;

      font-weight: font-weight(bold);
      text-decoration: none;
      border: none;
      margin: 0;

      &:hover {
        text-decoration: underline;
        color: var(--brand-500);
      }
    }
  }
</style>
